* {
  touch-action: manipulation;
}

.MuiContainer-root.makeStyles-container-76.MuiContainer-maxWidthLg {
  padding-top: 0 !important;
}
.MuiToolbar-regular {
  min-height: 0 !important;
}

.Reading:not(.Reading0) {
  display: none;
}
.Listening:not(.Listening0) {
  display: none;
}
.Reading,
.Listening {
  font-size: 24px;
  font-weight: bold;
  display: block;
}
.questionBoxes {
  display: inline-block;
  height: 50px;
  width: 50px;
  background: lightgray;
  margin: 10px;
  padding: 17px;
  cursor: pointer;
  font-weight: bold;
  position: relative;
}

.questionBoxes.question-answered {
  background: blue;
  color: white;
}
.questionBoxes:hover {
  background: darkgray;
}
/* .question-details {
	font-size: 20px;
} */
.reading-main-box {
  margin-top: 30px;
  position: relative;
}
.reading-main-box:before {
  content: "Reading";
  position: absolute;
  top: -25px;
  font-weight: bold;
  font-size: 1.25rem;
}
.listening-main-box {
  margin-top: 30px;
  position: relative;
}
.listening-main-box:before {
  content: "Listening";
  position: absolute;
  top: -25px;
  font-weight: bold;
  font-size: 1.25rem;
}
.question {
  display: none;
  background: rgba(255, 255, 255, 0.95);
  padding-left: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  border-radius: 20px;
  font-size: 16px;
}
.question h4,
.question h3 {
  margin: 10px;
}
.question-flex {
  display: flex;
  flex-direction: column;
}
.omLogoBg {
  position: absolute;
  opacity: 0.1;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  display: none;
}

.question .question-image {
  max-width: 600px;
  text-align: center;
}

.question .question-image > img {
  width: 60%;
  height: auto;
  margin: 0 auto;
}

.qno-all {
  display: flex;
}

.question-left {
  width: 58%;
}

.question-right {
  border-left: 1px solid black;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40%;
  justify-content: center;
}
.question-right:not(.nextprevBtn) {
  overflow-x: hidden;
}

.question-right img {
  width: 150px;
  height: 150px;
}
.question-right .nextprevBtn {
  width: 70%;
  border-top: 1px solid black;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.question-right > button {
  width: 80%;
}
.question-right div p {
  margin: 0px;
  margin-bottom: 5px;
}

.question-right p label {
  position: relative;
}

.question-right div[role="radiogroup"] p label .MuiFormControlLabel-label {
  position: absolute;
  top: 0;
  width: 25px;
  border: 2px solid black;
  border-radius: 50%;
  text-align: center;
}

.question-right
  div[role="radiogroup"]
  p
  label
  .MuiFormControlLabel-label.question-checked {
  background-color: rgb(0, 174, 255);
}

.question-right p label .MuiIconButton-label {
  opacity: 0;
}

/* exam-header
set-details
question-details */

.exam-header {
  display: flex;
  flex-direction: column;
}

.question-main-body {
  background: url("../../assets/images/om.png") center no-repeat !important;
}

.set-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.question-details {
  display: flex;
  justify-content: space-between;
  border: 2px solid black;
  width: 100%;
}
.question-details div {
  border-right: 2px solid black;
  padding: 15px 25px;
}

/* overlay  */
.overlay-questions {
  padding: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  padding-top: 20px;
  bottom: 0;
  z-index: 9;
  background: #fafafa;
  display: block;
}

.question-main-box {
  padding-left: 10px;
  width: 80%;
  margin-left: 10px;
}

.overlay-questions i {
  display: block;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 20px;
}

.audio-button {
  height: 100px;
  width: 100px;
  background: url("../../assets/images/headphone.png");
  background-size: cover;
  border-radius: 10px;
  display: block;
  margin: auto;
}

/* changing mui  */
.MuiTouchRipple-root {
  display: none !important;
}
.MuiSvgIcon-root {
  transition: none !important;
}

/* result page  */
.resultParent h1 {
  text-align: center;
  color: darkgreen;
  font-size: xx-large;
}
.resultBox {
  gap: 10px;
  width: 70%;
  min-height: 60vh;
  margin: auto;
}
.resultBox p {
  display: inline-block;
  height: 50px;
  width: 50px;
  background: lightgray;
  margin: 10px;
  padding: 17px;
  cursor: pointer;
  font-weight: bold;
}

.resultQuestionBoxes.right {
  background: lightgreen;
}
.resultQuestionBoxes.wrong {
  background: salmon;
}

.resultParent .result-picture {
  width: 100px;
  height: 100px;
  margin: 3rem auto;
}

.resultParent .result-picture img {
  width: 100%;
  height: 100%;
}

.result-student-details,
.result-answers {
  font-size: large;
  text-align: center;
  font-weight: bolder;
}

.resultParent hr {
  width: 60%;
  color: lightgray;
  margin: 2rem auto 1rem auto;
}

.result-answers .number {
  font-size: xx-large;
  color: darkgreen;
}

/* Font size for tablet */
@media only screen and (orientation: landscape) and (max-width: 1000px) {
  /* .question {
    font-size: 25px;
  } */
  .question-left {
    font-size: 17px;
  }
  .exam-header .question-details {
    font-size: 15px;
  }
  .exam-header .question-details div {
    padding: 15px;
  }
  .nextprevBtn button:not(:first-child) {
    margin-left: 10px;
  }
  .nextprevBtn button span {
    font-size: 10px;
  }
  .question-right .nextprevBtn {
    width: 100%;
  }
  .question-right button:not(p > button) {
    width: 100%;
  }
}

/* change orientation when device is in portrait  */
@media screen and (orientation: portrait) {
  #landscapeMode {
    position: absolute;
    height: 100vh;
    width: 100%;
    background: #3d3d3d;
    z-index: 999999;
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
  }
}
